import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { TestAsFoundResultValue, TestResultDropdownOptions } from '../../../TestResultComponent/TestResult.formulas';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const TestResultSectionDataP630 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [
                {
                    key: 'F1042LocalOpenCloseSuccessfulLabel',
                    label: 'Local Open/Close Successful',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F1042LocalOpenCloseSuccessful',
                    value: formData.F1042LocalOpenCloseSuccessful,
                    isDropDown: true,
                    placeholder: Placeholder.SELECT,
                    options: [
                        { name: Placeholder.SELECT, value: '' },
                        { name: 'Yes', value: 'Yes' },
                        { name: 'No', value: 'No' },
                        { name: 'N/A', value: 'N/A' }],
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    inputTextAlign: 'center',
                },
                {
                    key: 'F1044HandWheelOperationSuccessfulLabel',
                    label: 'Hand Wheel Operation Successful',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F1044HandWheelOperationSuccessful',
                    value: formData.F1044HandWheelOperationSuccessful,
                    isDropDown: true,
                    isRequired: true,
                    placeholder: Placeholder.SELECT,
                    options: [
                        { name: Placeholder.SELECT, value: '' },
                        { name: 'Yes', value: 'Yes' },
                        { name: 'No', value: 'No' },
                        { name: 'N/A', value: 'N/A' }],
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    inputTextAlign: 'center',
                    textAlignLabel: 'end',
                }
            ]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F343PhysicalValvePositionAfterTestLabel',
                    label: 'Physical Valve Position After Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F343PhysicalValvePositionAfterTest',
                    value: formData.F343PhysicalValvePositionAfterTest,
                    isDropDown: true,
                    placeholder: Placeholder.SELECT_PHYSICAL_POSITION,
                    options: [
                        { name: Placeholder.SELECT_PHYSICAL_POSITION, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    inputTextAlign: 'center',
                    borderTop: '0'
                },
                {
                    key: 'F347HmivalvePositionAfterTestLabel',
                    label: 'HMI Valve Position After Test',
                    xs: 4,
                    isLabel: true,
                    labelWidth,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right',
                    padding: paddingLabel,
                },
                {
                    key: 'F347HmivalvePositionAfterTest',
                    value: formData.F347HmivalvePositionAfterTest,
                    isDropDown: true,
                    placeholder: Placeholder.SELECT_HMI_POSITION,
                    options: [
                        { name: Placeholder.SELECT_HMI_POSITION, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    inputTextAlign: 'center',
                    textAlignLabel: 'end',
                    borderTop: '0',
                    borderBottom: '0'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F1043LocalRemoteSelectionAfterTestLabel',
                    label: 'Local/Remote Selection After Test',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    padding: paddingLabel,
                },
                {
                    key: 'F1043LocalRemoteSelectionAfterTest',
                    value: formData.F1043LocalRemoteSelectionAfterTest,
                    isDropDown: true,
                    placeholder: Placeholder.Select_Status,
                    options: [
                        { name: Placeholder.Select_Status, value: '' },
                        { name: 'Local', value: 'Local' },
                        { name: 'Remote', value: 'Remote' },
                        { name: 'Stop/Off', value: 'Stop/Off' }],
                    isRequired: true,
                    width: '100%',
                    labelWidth: '0%',
                    marginBottom: '15px',
                    xs: 2,
                    inputTextAlign: 'center',
                    borderTop: '0'
                },
                {
                    key: 'F85TestResultLabel',
                    label: 'Test Result',
                    xs: 2,
                    labelWidth,
                    paddingLabel,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                    textAlign: 'right'
                },
                {
                    key: 'F85TestResult',
                    value: TestAsFoundResultValue(formData) ? '' : formData.F85TestResult,
                    isRequired: true,
                    isDropDown: true,
                    placeholder: 'Select Test Result',
                    options: TestResultDropdownOptions(formData),
                    xs: 4,
                    labelWidth: '0%',
                    width: '100%',
                }
            ]
        },
    ]

});
