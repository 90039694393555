export const TestResultSectionDataP711 = (selectedTag, formName, formData) => {
    let TXReadingAfterTest;
    if (['P7-11', 'P8-13.1', 'P7-35'].includes(formName)) {
        TXReadingAfterTest = 'Transmitter Reading After Test';
    } else {
        TXReadingAfterTest = 'TX Reading After Test';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F82HmiReadingAt',
                    label: 'HMI Reading After Test',
                    value: formData.F82HmiReadingAt,
                    isRequired: true,
                    xs: 4,
                    type: 'number',
                    direction: 'rtl',
                    marginRight: '0.5rem',
                    maxLength: '10',
                    labelWidth: '50%',
                    width: '50%',
                    height: '1.2rem',
                    textAlign: 'center'
                },
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: 1
                },
                {
                    key: 'TransmitterReadingAfterTestLabel',
                    label: TXReadingAfterTest,
                    labelWidth: '97%',
                    xs: 3,
                    padding: '5px 0 0 0',
                    isLabel: 'true',
                    textAlign: 'end',
                    fontSize: '9pt',
                    fontWeight: '600'
                },
                {
                    key: 'F786TransmitterReadingAfterTest',
                    value: formData.F786TransmitterReadingAfterTest,
                    type: 'number',
                    maxLength: '10',
                    isRequired: true,
                    labelWidth: '0',
                    xs: 4,
                    height: '1.2rem',
                    textAlign: 'center',
                    width: '100%',
                    marginLeft: '0.8px'
                }]
            }]
    };
};
