import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { AsFoundResultP630Formula } from './P630.formulas';

export const paddingVal = '4px 0 0 8px';
export const AsFoundTestTableSectionDataP630 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'AsFoundTest',
                label: 'As-Found Test',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                padding: '5px 0 5px 8px'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'F1030AftexpectedValveActionOnDemandLabel',
                    label: 'Expected Valve Action On Demand',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1030AftexpectedValveActionOnDemand',
                    value: formData.F1030AftexpectedValveActionOnDemand,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.Select_Action,
                    options: [{ name: Placeholder.Select_Action, value: '' },
                    { name: 'To be Opened', value: 'To be Opened' },
                    { name: 'To be Closed', value: 'To be Closed' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    borderBottom: '0',
                    xs: 2,
                    height: '1.5rem'
                },
                {
                    key: 'F1033AfthmivalvePositionAfterDemandLabel',
                    label: 'HMI Valve Position After Demand',
                    xs: 4,
                    labelWidth: '97%',
                    padding: paddingLabel,
                    isLabel: true,
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1033AfthmivalvePositionAfterDemand',
                    value: formData.F1033AfthmivalvePositionAfterDemand,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT_HMI_POSITION,
                    options: [{ name: Placeholder.SELECT_HMI_POSITION, value: '' },
                    { name: 'Open', value: 'Open' },
                    { name: 'Closed', value: 'Closed' },
                    { name: 'Intermediate', value: 'Intermediate' }],
                    labelWidth: '0',
                    width: '98%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '1.5rem',
                    borderRight: '0'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'F1031AftphysicalValvePositionAfterDemandLabel',
                    label: 'Physical Valve Position After Demand',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1031AftphysicalValvePositionAfterDemand',
                    value: formData.F1031AftphysicalValvePositionAfterDemand,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.SELECT_PHYSICAL_POSITION,
                    options: [{ name: Placeholder.SELECT_PHYSICAL_POSITION, value: '' },
                    { name: 'Open', value: 'Open' },
                    { name: 'Closed', value: 'Closed' },
                    { name: 'Intermediate', value: 'Intermediate' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    borderBottom: '0',
                    height: '1.5rem'
                },
                {
                    key: 'F1034AftvalveTravelTimeLabel',
                    label: 'Valve Travel Time (sec)',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F1034AftvalveTravelTime',
                    value: formData.F1034AftvalveTravelTime,
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    borderBottom: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    height: '1.25rem',
                    type: 'number',
                    maxLength: '10'
                }
            ]
        },
        {
            key: 4,
            fields: [
                {
                    key: 'F1032AftobservedValveMovementLabel',
                    label: 'Observed Valve Movement',
                    xs: 4,
                    labelWidth: '100%',
                    padding: paddingVal,
                    isLabel: true,
                    fontWeight: '600',
                    fontSize: '9pt',
                },
                {
                    key: 'F1032AftobservedValveMovement',
                    value: formData.F1032AftobservedValveMovement,
                    isRequired: true,
                    isDropdown: true,
                    placeholder: Placeholder.Select_Valve_Movement,
                    options: [{ name: Placeholder.Select_Valve_Movement, value: '' },
                    { name: 'Smooth', value: 'Smooth' },
                    { name: 'Abnormal', value: 'Abnormal' }],
                    labelWidth: '0%',
                    width: '100%',
                    inputTextAlign: 'center',
                    xs: 2,
                    height: '1.5rem'
                },
                {
                    key: 'F1035AftmaxTorqueDuringValveTravelLabel',
                    label: 'Max Torque During Valve Travel',
                    xs: 4,
                    isLabel: true,
                    fontWeight: '600',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F1035AftmaxTorqueDuringValveTravel',
                    value: formData.F1035AftmaxTorqueDuringValveTravel,
                    width: '100%',
                    labelWidth: '0%',
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    borderBottom: '0',
                    height: '1.25rem',
                    maxLength: '20'
                }
            ]
        },
        {
            key: 5,
            fields: [
                {
                    key: 'Nodata',
                    isLabel: true,
                    xs: formData.F1032AftobservedValveMovement === 'Abnormal' ? 4 : 6,
                },
                ...(formData.F1032AftobservedValveMovement === 'Abnormal'
                    ? [
                        {
                            key: 'displaymessage',
                            label: 'Corrective work may be required to fix the abnormal valve movement.',
                            isLabel: true,
                            fontWeight: '600',
                            labelWidth: '100%',
                            padding: '0 0 0 2px',
                            fontSize: '8.5pt',
                            color: '#ea7c34',
                            xs: 4,
                        }] : []),
                {
                    key: 'F102AftResultLabel',
                    label: 'As-Found Result',
                    xs: formData.F1032AftobservedValveMovement === 'Abnormal' ? 2 : 4,
                    isLabel: true,
                    fontWeight: '700',
                    labelWidth,
                    padding: paddingLabel,
                    fontSize: '9pt',
                    textAlign: 'right',
                },
                {
                    key: 'F102AftResult',
                    value: AsFoundResultP630Formula(formData),
                    width: '100%',
                    readOnly: true,
                    labelWidth: '0%',
                    isCalculatedNew: true,
                    xs: 2,
                    textAlign: 'center',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    fontWeight: '700',
                    isLabelBold: true,
                    borderBottom: '0',
                    height: '1.25rem',
                    marginBottom: '4px'
                }
            ]
        },
    ]

});
