import axios from 'axios';
import informationStore from 'utils/informationDB';
import offlineStore from 'utils/OfflineDB';
import DraftStore from 'utils/DraftDB';
import { set, entries, clear } from 'idb-keyval';
import _ from 'lodash';
import { APIEndpoints } from 'helpers/APILists';
import { HOME_ACTIONS, INFO_PAGE } from './Home.constants';
import { MESSAGE_TYPE } from '../../common/common.constants';
import { trackErrors } from '../Admin/AdminComponents/helper';

export function exampleAction(text) {
  return dispatch => {
    dispatch({
      type: 'AUTH_SUCCESS',
      payload: { success: true },
      meta: { message: text }
    });
  };
}

export const setCurrentView = (view) => ({
  type: HOME_ACTIONS.SET_CURRENT_VIEW,
  payload: { currentView: view }
});

export const setPreviousScreen = (screen) => ({
  type: HOME_ACTIONS.SET_PREVIOUS_SCREEN,
  payload: { previousScreen: screen },
});

export const getAllRegionData = () => async (dispatch, getState) => {
  const { Home: { bpRegionName, selectedAssetName } } = getState();
  dispatch({
    type: HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BP_REGION_DATA_PENDING,
    payload: {}
  });
  axios.get(`${APIEndpoints.HOME_GetBPRegionAssets}/${bpRegionName}`)
    .then((res) => {
      for (const key in localStorage) {
        if (key.includes('-image')) {
          localStorage.removeItem(key);
        }
      }
      const regionDetails = res.data;
      const regionName = regionDetails[0]?.BpRegion.toLowerCase();
      const groupNameList = _.uniq(regionDetails.map(x => x.MratGroup));
      const assetListWithoutImageButKey = regionDetails.map((data) => {
        localStorage.setItem(`${data.BpSiteName.toUpperCase()}-image`, `${data.AssetImg}`);
        return {
          ...data,
          AssetImg: `${data.BpSiteName.toUpperCase()}-image`
        };
      });
      dispatch({
        type: HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BP_REGION_DATA_SUCCESS,
        payload: {
          regionDetails: assetListWithoutImageButKey,
          regionName
        }
      });
      if (groupNameList.length === 1 && !selectedAssetName) {
        dispatch(saveGroupName(groupNameList[0]));
      }
      if (regionName) {
        dispatch(getDetailsByRegion(regionName));
      }
    })
    .catch((err) => {
      dispatch({
        type: HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BP_REGION_DATA_FAILURE,
        payload: {},
        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
      });
    });
};

export const getAllBarrierType = () => async (dispatch, getState) => {
  const { Home: { selectedWorkType } } = getState();
  axios.get(APIEndpoints.HOME_GetAllBarrierTypes)
    .then((res) => {
      const allBarrierTypeList = res.data.BarrierTypeList;
      const selectedBarrierType = allBarrierTypeList.find((data) => data.WorkType.toLowerCase() === selectedWorkType.toLowerCase() && data.DefaultBarrierType)
        ?.BarrierName.toLowerCase();
      const barrierTypeList = allBarrierTypeList.filter((data) => data.WorkType.toLowerCase() === selectedWorkType.toLowerCase())
        .sort((a, b) => a.BarrierOrder - b.BarrierOrder)
        .map((item) => item.BarrierName);
      dispatch({
        type: HOME_ACTIONS.HOME_ACTIONS_GET_ALL_BARRIER_TYPE_DATA_SUCCESS,
        payload: {
          allBarrierTypeList,
          barrierTypeList,
          selectedBarrierType
        }
      });
    });
};

export const getDetailsByRegion = (regionName) => async (dispatch) => {
  const filterData = { Region: regionName };
  axios.post(APIEndpoints.GetAllRegionDisclaimer, filterData)
    .then((res) => {
      const disclaimer = res.data.Disclaimer;
      const draftStatus = res.data.DraftStatus;
      const draftNotice = res.data.DraftNotice;
      const draftDuration = res.data.TimeInDraftAllowed;
      return dispatch({
        type: HOME_ACTIONS.HOME_ACTIONS_GET_DETAILS_BY_REGION,
        payload: {
          disclaimer,
          draftStatus,
          draftDuration,
          draftNotice
        }
      });
    });
};

export const getAllWorkType = () => async (dispatch, getState) => {
  axios.get(APIEndpoints.HOME_GetAllWorkTypes)
    .then((res) => {
      const workTypeList = res.data.WorkTypeList.filter((item) => item !== null);
      return dispatch({
        type: HOME_ACTIONS.HOME_ACTIONS_GET_ALL_WORK_TYPE_DATA_SUCCESS,
        payload: {
          workTypeList
        }
      });
    });
};

export const getTrfMasterLists = () => async (dispatch, getState) => {
  axios.get(APIEndpoints.HOME_GetTrfMasterLists)
    .then((res) => {
      const trfMasterListData = res.data.map((item) => item);
      const trfMasterList = trfMasterListData.sort((a, b) => a.TrfId.localeCompare(b.TrfId, undefined, {
        numeric: true,
        sensitivity: 'base'
      }));
      return dispatch({
        type: HOME_ACTIONS.HOME_ACTIONS_GET_TRF_MASTER_LISTS_DATA_SUCCESS,
        payload: {
          trfMasterList
        }
      });
    });
};

export const getAllTagSelectionMOScreenData = () => async (dispatch, getState) => {
  const { Home: { selectedAssetName, selectedBarrierType, regionName, selectedWorkType } } = getState();
  const filterData = { Asset: selectedAssetName, Region: regionName, WorkType: selectedWorkType, BarrierType: selectedBarrierType };
  axios.post(APIEndpoints.GetVwSearchTag, filterData)
    .then((res) => {
      const tags = res.data.map((item) => item);
      // IF NO DATA then get data from the second url
      if (tags && tags.length > 0) {
        return dispatch({
          type: HOME_ACTIONS.HOME_ACTIONS_GET_TAG_RECORDS_DATA_SUCCESS,
          payload: {
            tags
          }
        });
      }
      return true;
    });
};

export const getAllTagBiSelectionMOScreenData = () => async (dispatch, getState) => {
  const { Home: { selectedAssetName, selectedBarrierType, regionName, selectedWorkType } } = getState();
  const filterData = { Asset: selectedAssetName, Region: regionName, WorkType: selectedWorkType, BarrierType: selectedBarrierType };
  axios.post(APIEndpoints.GetVwSearchTagBi, filterData)
    .then((res) => {
      const tagsBi = res.data.map((item) => item);
      return dispatch({
        type: HOME_ACTIONS.HOME_ACTIONS_GET_TAG_BI_RECORDS_DATA_SUCCESS,
        payload: {
          tagsBi
        }
      });
    });
};

export const saveGroupName = (groupName) => (dispatch, getState) => {
  const { Home: { regionDetails } } = getState();
  const assetNameList = regionDetails.filter(x => x.MratGroup.toLowerCase() === groupName.toLowerCase()).map((item) => item.BpSiteAliasName.toUpperCase());
  dispatch({
    type: HOME_ACTIONS.HOME_SET_GROUP_NAME,
    payload: {
      selectedGroupName: groupName,
      assetNameList,
      selectedAssetName: '',
      selectedAssetAliasName: ''
    }
  });
};

export const saveAssetName = (assetName) => (dispatch, getState) => {
  const { Home: { regionDetails, selectedGroupName } } = getState();
  const assetDetails = regionDetails.find((item) => item.MratGroup.toUpperCase() === selectedGroupName.toUpperCase()
    && item.BpSiteAliasName.toUpperCase() === assetName.toUpperCase());
  dispatch({
    type: HOME_ACTIONS.HOME_SET_ASSET_NAME,
    payload: {
      selectedAssetAliasName: assetName,
      selectedAssetName: assetDetails?.BpSiteName.toLowerCase()
    }
  });
};

export const saveWorkType = (workType) => (dispatch, getState) => {
  const { Home: { allBarrierTypeList, selectedBarrierType, selectedWorkType } } = getState();
  let updateSelectedBarrierType = selectedBarrierType;
  const barrierTypeList = allBarrierTypeList.filter((data) => data.WorkType.toLowerCase() === workType.toLowerCase())
    .sort((a, b) => a.BarrierOrder - b.BarrierOrder)
    .map((item) => item.BarrierName);
  if (workType.toLowerCase() !== selectedWorkType) {
    updateSelectedBarrierType = allBarrierTypeList.find((data) => data.WorkType.toLowerCase() === workType.toLowerCase() && data.DefaultBarrierType)
      ?.BarrierName.toLowerCase();
  }
  dispatch({
    type: HOME_ACTIONS.HOME_SET_WORK_TYPE_NAME,
    payload: {
      barrierTypeList,
      selectedWorkType: workType,
      selectedBarrierType: updateSelectedBarrierType
    }
  });
};

export const saveBarrierType = (barrierType) => (dispatch) => dispatch({
  type: HOME_ACTIONS.HOME_SET_BARRIER_TYPE_NAME,
  payload: {
    selectedBarrierType: barrierType
  }
});

export const saveUsersGroupDetails = (membergroupId) => async (dispatch) => {
  await axios.get(`${APIEndpoints.GetMratSecurityGroupById}/${membergroupId}`).then((res) => {
    const memberGroup = res.data;
    const bpRegionName = memberGroup?.BpRegion || 'No region found';
    return dispatch({
      type: HOME_ACTIONS.HOME_SET_USERSROLEANDREGION,
      payload: {
        bpRegionName,
        bpRegionNameAlias: memberGroup?.BpRegionAlias,
        userRole: memberGroup?.Role?.RoleName || 'cannot find your role',
        adGroupType: memberGroup?.AdGroupType
      },
    });
  });
};

export const cleanupHomeStates = () => (dispatch) => dispatch({
  type: HOME_ACTIONS.HOME_CLEANUP_NONESSENTAIL_STATES,
  payload: {
    selectedAssetName: ''
  },
});

export const getAllInfoDocuments = () => async (dispatch, getState) => {
  const { Home: { currentView } } = getState();
  dispatch({
    type: HOME_ACTIONS.INFOPAGE_GETDOCUMENTS_PENDING,
  });
  await axios.get(currentView !== INFO_PAGE.VIEW
    ? `${APIEndpoints.GetInformation}/${INFO_PAGE.USER}`
    : APIEndpoints.InfoDocuments).then((res) => {
      const activePDF = res.data.filter((data) => data.Pdfstatus);
      set('InformationPdf', activePDF, informationStore);
      dispatch({
        type: HOME_ACTIONS.INFOPAGE_GETDOCUMENTS_SUCCESS,
      });
    }).catch((err) => {
      dispatch({
        type: HOME_ACTIONS.INFOPAGE_GETDOCUMENTS_FAILURE,
        meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
      });
    });
};

export const updateMRATSyncInvalidationStatus = (localState) => (dispatch) => dispatch({
  type: HOME_ACTIONS.UPDATE_INVALIDATION_STATUS,
  payload: {
    syncInvalidationStatus: localState
  }
});

export const disableMRATSyncButton = (buttonStatus) => (dispatch) => dispatch({
  type: HOME_ACTIONS.UPDATE_SYNC_BUTTON_STATUS,
  payload: {
    syncButtonStatus: buttonStatus
  }
});

export const checkOfflineDataPresent = () => async (dispatch) => {
  const data = await entries(offlineStore);
  dispatch({
    type: HOME_ACTIONS.CHECK_OFFLINE_DATA_PRESENT,
    payload: {
      isOfflineDataPresent: data.length > 0
    }
  });
};

export const setDataLoaded = () => (dispatch) => dispatch({
  type: HOME_ACTIONS.SET_DATA_LOADED,
  payload: {
    isDataLoaded: true
  }
});

export const SendDraftHomeData = () => (dispatch, getState) => {
  const { AppData: { isMRATOnline }, Home: { selectedAssetAliasName, bpRegionNameAlias } } = getState();
  dispatch({
    type: HOME_ACTIONS.GET_DRAFT_DATA,
  });
if (isMRATOnline) {
    const payload = {
      Asset: selectedAssetAliasName,
      Region: bpRegionNameAlias,
    };
    clear(DraftStore).then(() => {
      axios.post(APIEndpoints.GetInDraftTrfList, payload)
        .then((response) => {
            const res = response.data;
            res.forEach((item) => {
              const Mi = item.F86Mi ?? item.F17Mi;
              const Mo = item.F20Mo ?? item.F89Mo;
              const uniqueFormName = `${Mi}_${Mo}_${item.F2SapFloc}`;
              const draftdata = { body: item };
              draftdata.body = item;
              set(`Forms_${uniqueFormName}`, draftdata, DraftStore);
            });
        })
        .catch((err) => {
          trackErrors({
            messageType: MESSAGE_TYPE.ERROR,
            message: err.message,
            exception: err
          });
        });
    });
  }
  };
