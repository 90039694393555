import { FieldHasValue } from 'helpers/Validation';

export const AsFoundResultP630Formula = (formData) => {
    if (FieldHasValue(formData.F1030AftexpectedValveActionOnDemand) && FieldHasValue(formData.F1031AftphysicalValvePositionAfterDemand)) {
        if ((formData.F1030AftexpectedValveActionOnDemand === 'To be Opened' && formData.F1031AftphysicalValvePositionAfterDemand === 'Open')
        || (formData.F1030AftexpectedValveActionOnDemand === 'To be Closed' && formData.F1031AftphysicalValvePositionAfterDemand === 'Closed')) {
            return 'PASS';
        }
        return 'FAIL';
    }
    return '';
};
