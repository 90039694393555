import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { connect } from 'react-redux';
import DataTable from 'components/DataTable/DataTable';
import { StyledLabel } from 'elements/';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { DarkBackground, Spinner } from 'elements/Spinner/Spinner.styled';
import { SecondaryHeaderButton } from 'elements/Button/HeaderButton';
import { MatchSRA } from 'helpers/Validation';
import { updatedTableDefinition } from './TagSelectionMOScreen.constants';
import { setHdLocationSortOrder, getTestStatusFilteredData, searchFLOC } from './TagSelectionMOScreen.actions';
import { parseDate } from '../../../helpers/DateFormatter';
import {
  resetFormData, setDataForFormTagSelected, clearSelectedTRF,
  getAllMOImages, setIsEditimage, clearEditImageList, resetTestPointVal,
  saveSpinnerVisibleData
} from '../Forms/Forms.actions';
import { MRAT_USER_ROLE } from '../Home/Home.constants';
import SearchBox from '../../../elements/SearchBox/SearchBox';
import TagSelectionFilter from './SubSection/TagSelectionFilter/TagSelectionFilter';
import { checkOfflineDataPresent, setCurrentView, setPreviousScreen, SendDraftHomeData } from '../Home/Home.actions';
import { sortTestEquipment, searchTestEquipment, getTestMakeModelData } from '../TestEquipment/TestEquipment.actions';
import { clearSRAData, GetSRAForm } from '../Forms/FormsP8.actions';
import { getPrintImage } from '../Forms/TRFImaging.actions';
import { isMobile as isTablet } from 'react-device-detect';

const TagSelectionMOScreenPage = (props) => {
  const { setCurrentAppView, totalCount, selectedRow, selectedRowTags, triggerSort, userRole,
    initialiseDataForFormTagSelected, isMRATOnline, resetFormDataValues, clearSelectedTRFValue,
    selectedBarrierType, clearFilters, selectedRowTagsTableData, searchedRowTagsTableData, sortColumn, setSortedTestEquipmentView,
    sortStatusAscOrder, sortDeviceTypeAscOrder, fetchTestEquipmentData, setIsEditImage, clearEditimageList,
    searchStringForTestEq, searchTestEquipmentRecords, fetchAllImages, isTableLoading, selectedWorkType,
    loadUpdatedSelectedTagsForTagMOScreenData, deleteSRAData, loadSRAFormData, fetchTRFImages,
    flocSearchString, triggerSearch, clearTestPointValue, trfMasterList, setPreviousAppScreen, saveSpinnerVisible, isOfflineDataPresent, checkOfflineFormDataPresent, homeDraftData } = props;
  const [searchString, setSearchString] = useState(flocSearchString);
  let tableData = [];
  if (clearFilters && searchString.length === 0) {
    tableData = selectedRowTags;
  } else if (searchString) {
    tableData = searchedRowTagsTableData;
  } else {
    tableData = selectedRowTagsTableData;
  }
  const showFilters = selectedBarrierType === ('M6 F&G').toLowerCase() || (selectedBarrierType === 'all' && selectedWorkType.toLowerCase() === 'preventative');
  const [printButtonVisible, setPrintButtonVisible] = useState(false);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const showGrid = isMRATOnline && !isTablet && (MRAT_USER_ROLE.View !== userRole);
  let checkedFormsData = [];
  useEffect(() => {
    deleteSRAData();
    clearTestPointValue();
    loadUpdatedSelectedTagsForTagMOScreenData(selectedRow);
    saveSpinnerVisible(true);
    homeDraftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMRATOnline]);
 useEffect(() => {
    flocSearchString && handleSearch(flocSearchString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowTagsTableData]);

  const sortButtonHandler = (columnName) => {
    triggerSort(columnName);
  };

  const testEquipmentButtonHandler = async () => {
    setPreviousAppScreen('tagselectionmoscreen');
    setCurrentAppView('testequipment');
    await fetchTestEquipmentData();
    searchStringForTestEq && searchTestEquipmentRecords(searchStringForTestEq);
    sortColumn !== '' && (sortColumn === 'DeviceType'
      ? setSortedTestEquipmentView(sortColumn, sortDeviceTypeAscOrder)
      : setSortedTestEquipmentView(sortColumn, sortStatusAscOrder));
  };

  const handleArrowButton = (dataRow, isMRATOnlineParameter) => {
    initialiseDataForFormTagSelected(dataRow);
    if (isMRATOnlineParameter || !dataRow.TrfRowId || dataRow.InDraftState) {
      // There are 3 places in redux store state where the form will fetch the data from:
      // Forms: formData
      // Forms: selectedTag
      // Forms: selectedTrfId
      // All of the above data must be cleared first
      resetFormDataValues();
      clearSelectedTRFValue();
      if (trfMasterList.find(x => x.TrfId === dataRow.TrfId.trim())?.Status === 'Inactive') {
        setCurrentAppView('NA');
      } else if (dataRow.TrfId.trim() !== '') {
        setCurrentAppView(dataRow.TrfId.trim());
      } else {
        setCurrentAppView('NA');
      }
      fetchAllImages();
      MatchSRA(dataRow?.TrfId?.trim()) && loadSRAFormData(dataRow).finally(() => saveSpinnerVisible(false));
    }
    setIsEditImage(false, {});
    clearEditimageList();
  };
useEffect(() => {
  checkOfflineFormDataPresent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isOfflineDataPresent, isMRATOnline]);
  const getParsedTableData = (isMRATOnlineParameter, userRoleParameter) => tableData.map((item) => ({
    ...item,
    DateTimeTested: item.DateTested ? new Date(item.DateTested) : '',
    DateTested: item.DateTested ? parseDate(new Date(item.DateTested)) : '',
    HideArrow:
      // No one can see already filled forms in the OFFLINE MODE
      (!isMRATOnlineParameter && item.TrfRowId && !item.InDraftState)
      // Only technicians are allowed to open unfilled forms
      || ((MRAT_USER_ROLE.View === userRoleParameter) && !item.TrfRowId)
      || (isMRATOnline && isOfflineDataPresent),
  }));

  const handleSearch = (searchValue) => {
    setSearchString(searchValue);
    triggerSearch(searchValue, selectedRow.HdWorkOrder);
  };

  const handlePrintButtonVisible = (visibleState, checkedRecords) => {
    setPrintButtonVisible(visibleState);
    checkedFormsData = [...checkedRecords];
  };

  const handlePrintButton = async () => {
    setSpinnerVisible(true);
    const trfImages = await fetchTRFImages(checkedFormsData);
    const imgData = [];
    _.forEach(trfImages, (ele) => {
      imgData.push(`data:image/png;base64, ${ele.Trfimage1}`);
      ele.Trfimage2 && imgData.push(`data:image/png;base64, ${ele.Trfimage2}`);
      ele.Trfimage3 && imgData.push(`data:image/png;base64, ${ele.Trfimage3}`);
    });

    // hidden iframe to preview all the images on print screen
    const iframe = document.createElement('iframe');
    iframe.style.height = 0;
    iframe.style.visibility = 'hidden';
    iframe.style.width = 0;
    iframe.style.overflowX = 'visible';
    iframe.setAttribute('srcdoc', '<html><body></body></html>');
    document.body.appendChild(iframe);

    iframe.addEventListener('load', () => {
      const { body } = iframe.contentDocument;
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.display = 'block';
      div.style.left = 0;
      div.style.top = 0;
      div.style.bottom = 0;
      div.style.right = 0;
      div.style.padding = 0;
      body.appendChild(div);
      let count = 1;
      _.forEach(imgData, (img) => {
        const image = document.createElement('img');
        image.src = img;
        image.setAttribute('id', `img${count}`);
        image.style.width = '100%';
        // maxHeight for Letter paper height (1054px - 2*48px(narrow margin size))
        image.style.maxHeight = '958px';
        div.insertAdjacentElement('beforeend', image);
        count += 1;
      });
    });

    setSpinnerVisible(false);
    iframe.contentWindow.print();
    iframe.contentWindow.addEventListener('beforeprint', () => {
      const images = iframe.contentWindow.document.querySelectorAll('img');
      _.forEach(images, (img) => {
        const ratio = img.naturalHeight / img.naturalWidth;
        // calculate the bottom margin to be added to image to make each image appear on distinct page
        // 958 is the max height mentioned above
        // 1.29 is the letter size ratio (1054/816)px
        if (ratio < 1.29) {
          const margin = Math.abs(958 - (ratio / 1.29 * 958));
          iframe.contentWindow.document.getElementById(img.id).style.marginBottom = `${margin}px`;
        }
      });
    });
    iframe.contentWindow.addEventListener('afterprint', () => {
      iframe.parentNode.removeChild(iframe);
    });
  };

  return (
    <SiteWrapper
      headerText={
        (
          <div style={{ textAlign: 'center' }}>
            <span>
              Tag Selection
              <br />
              <span id="TagSelectionMONumber">{`MO  ${selectedRow.HdWorkOrder}`}</span>
            </span>
          </div>
        )
      }
      ButtonSection={(
        (MRAT_USER_ROLE.Technician === userRole || MRAT_USER_ROLE.Engineer === userRole)
        && (
          <SecondaryHeaderButton
            buttonText="Test Equipment Inventory"
            handleButton={testEquipmentButtonHandler}
          />
        )
      )}
      totalCount={totalCount}
      showTotalCount
      goBack="moselection"
      TagSelectionPrintButtonVisible={printButtonVisible && isMRATOnline}
      PrintButtonHandler={handlePrintButton}
    >
      <div className="container">
        {(selectedWorkType !== 'corrective') && (
          <Grid container spacing={2}>
            <Grid container item xs={12} style={{ padding: '4px 0 0 0' }}>
              <Grid item xs={2}>
                <StyledLabel marginLeft="1rem">
                  MI:
                </StyledLabel>
              </Grid>
              <Grid item xs={5}>
                <StyledLabel id={`Mi_${selectedRow.Mi}`} padding="5px 25px 5px 5px">
                  {selectedRow.Mi}
                </StyledLabel>
              </Grid>
              <Grid item xs={1}>
                <StyledLabel marginLeft="1rem">
                  MP:
                </StyledLabel>
              </Grid>
              <Grid item xs={3}>
                <StyledLabel id={`Mp_${selectedRow.MaintenanceItemPm}`}>
                  {selectedRow.MaintenanceItemPm}
                </StyledLabel>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={2}>
                <StyledLabel padding="0 0 5px 9px">
                  MI Description:
                </StyledLabel>
              </Grid>
              <Grid item xs={5}>
                <StyledLabel id={`MI_desc_${selectedRow.MaintenanceItemDescription}`} padding="0 0 5px 0">
                  {selectedRow.MaintenanceItemDescription}
                </StyledLabel>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container>
          {showGrid && <Grid xs={1} />}
          <Grid xs={2} item style={{ marginTop: '65px' }}>
            <SearchBox
              value={flocSearchString}
              handleSearch={handleSearch}
              name="Functional Location"
              height="27px"
              placeholder="Functional Location"
              fullWidth
              fontSize="7.5pt"
              padding="0px 5px"
              borderColor="#498205"
            />
          </Grid>
          {!showGrid && <Grid xs={1} />}
          <Grid xs={9} item>
            {showFilters && <TagSelectionFilter id="TagSelectionFilter" />}
          </Grid>
        </Grid>
        <DarkBackground disappear={!spinnerVisible}>
          <Spinner />
        </DarkBackground>
        <div className="row">
          <div className="col-xs-12">
            <DataTable
              id="TagSelectionMOScreenDataTable"
              tableDefinition={updatedTableDefinition}
              tableData={getParsedTableData(isMRATOnline, userRole)}
              isLoading={isTableLoading}
              showArrowButton
              sortButtonHandler={sortButtonHandler}
              actionButtonHandler={(dataRow) => handleArrowButton(dataRow, isMRATOnline)}
              height={selectedWorkType !== 'corrective' ? 'calc(100vh - 302px)' : 'calc(100vh - 223px)'}
              showCheckBox
              printButtonVisible={handlePrintButtonVisible}
              isMRATOnline={isMRATOnline}
              userRole={userRole}
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};

const mapStateToProps = ({ Home, MOSelection, TagSelectionMOScreen, AppData, TestEquipment, Forms }) => ({
  clearFilters: TagSelectionMOScreen.clearFilters,
  currentView: Home.currentView,
  flocSearchString: TagSelectionMOScreen.flocSearchString,
  selectedRow: MOSelection.selectedRow,
  selectedRowTags: TagSelectionMOScreen.selectedRowTags,
  searchedRowTagsTableData: TagSelectionMOScreen.searchedRowTagsTableData,
  selectedRowTagsTableData: TagSelectionMOScreen.selectedRowTagsTableData,
  totalCount: TagSelectionMOScreen.totalCount,
  userRole: Home.userRole,
  selectedBarrierType: Home.selectedBarrierType,
  isOfflineDataPresent: Home.isOfflineDataPresent,
  isMRATOnline: AppData.isMRATOnline,
  isTableLoading: TagSelectionMOScreen.tableLoading,
  sortColumn: TestEquipment.sortColumn,
  sortStatusAscOrder: TestEquipment.sortStatusAscOrder,
  sortDeviceTypeAscOrder: TestEquipment.sortDeviceTypeAscOrder,
  searchStringForTestEq: TestEquipment.searchStringForTestEq,
  sortHdLocationAscOrder: TagSelectionMOScreen.sortHdLocationAscOrder,
  trfFormImage: Forms.trfFormImage,
  trfMasterList: Home.trfMasterList,
  selectedWorkType: Home.selectedWorkType,
  selectedAssetAliasName: Home.selectedAssetAliasName,
  bpRegionNameAlias: Home.bpRegionNameAlias,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentAppView: (view) => dispatch(setCurrentView(view)),
  setPreviousAppScreen: (screen) => dispatch(setPreviousScreen(screen)),
  triggerSearch: (value, hdWorkOrder) => dispatch(searchFLOC(value, hdWorkOrder)),
  triggerSort: (columnName) => dispatch(setHdLocationSortOrder(columnName)),
  initialiseDataForFormTagSelected: (selectedTag) => dispatch(setDataForFormTagSelected(selectedTag)),
  resetFormDataValues: () => dispatch(resetFormData()),
  clearTestPointValue: () => dispatch(resetTestPointVal()),
  clearSelectedTRFValue: () => dispatch(clearSelectedTRF()),
  setSortedTestEquipmentView: (columnName, sortOrder) => dispatch(sortTestEquipment(columnName, sortOrder)),
  fetchTestEquipmentData: () => dispatch(getTestMakeModelData()),
  searchTestEquipmentRecords: (searchVal) => dispatch(searchTestEquipment(searchVal)),
  loadUpdatedSelectedTagsForTagMOScreenData: (data) => dispatch(getTestStatusFilteredData(data)),
  fetchAllImages: () => dispatch(getAllMOImages()),
  deleteSRAData: () => dispatch(clearSRAData()),
  loadSRAFormData: (selectedTag) => dispatch(GetSRAForm(selectedTag)),
  fetchTRFImages: (checkedForm) => dispatch(getPrintImage(checkedForm)),
  setIsEditImage: (isEdit, img) => dispatch(setIsEditimage(isEdit, img)),
  clearEditimageList: () => dispatch(clearEditImageList()),
  saveSpinnerVisible: (spinnerValue) => dispatch(saveSpinnerVisibleData(spinnerValue)),
  checkOfflineFormDataPresent: () => dispatch(checkOfflineDataPresent()),
  homeDraftData: () => dispatch(SendDraftHomeData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagSelectionMOScreenPage);

TagSelectionMOScreenPage.propTypes = {
  checkOfflineFormDataPresent: PropTypes.func,
  clearEditimageList: PropTypes.func,
  clearFilters: PropTypes.bool,
  clearSelectedTRFValue: PropTypes.func,
  clearTestPointValue: PropTypes.func,
  deleteSRAData: PropTypes.func,
  fetchAllImages: PropTypes.func,
  fetchTestEquipmentData: PropTypes.func,
  fetchTRFImages: PropTypes.func,
  flocSearchString: PropTypes.string,
  homeDraftData: PropTypes.func,
  initialiseDataForFormTagSelected: PropTypes.func,
  isMRATOnline: PropTypes.bool,
  isOfflineDataPresent: PropTypes.bool,
  isTableLoading: PropTypes.bool,
  loadSRAFormData: PropTypes.func,
  loadUpdatedSelectedTagsForTagMOScreenData: PropTypes.func,
  resetFormDataValues: PropTypes.func,
  saveSpinnerVisible: PropTypes.func,
  searchedRowTagsTableData: PropTypes.array,
  searchStringForTestEq: PropTypes.string,
  searchTestEquipmentRecords: PropTypes.func,
  selectedBarrierType: PropTypes.string,
  selectedRow: PropTypes.object,
  selectedRowTags: PropTypes.array,
  selectedRowTagsTableData: PropTypes.array,
  selectedWorkType: PropTypes.string,
  setCurrentAppView: PropTypes.func,
  setIsEditImage: PropTypes.func,
  setPreviousAppScreen: PropTypes.func,
  setSortedTestEquipmentView: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDeviceTypeAscOrder: PropTypes.bool,
  sortStatusAscOrder: PropTypes.bool,
  totalCount: PropTypes.number,
  trfMasterList: PropTypes.array,
  triggerSearch: PropTypes.func,
  triggerSort: PropTypes.func,
  userRole: PropTypes.string,
};
