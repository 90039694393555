/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SiteWrapper from 'components/SiteWrapper/SiteWrapper';
import { RefreshFormComponent } from 'components/RefreshFormComponent/RefreshFormComponent';
import { connect } from 'react-redux';
import { formatDate, formatDateToUTC } from 'helpers/DateFormatter';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { isMobile as isTablet } from 'react-device-detect';
import {
    Trim, MatchSRA, checkURVGreaterThanLRV, MatchOldTRFs, TestPointRange,
    displayPopUpMessage, checkTGCGreaterThanSetpointHH, checkTGCLessThanSetpointLL,
    conditionalValueSelector, icEngApprovedValueYes
} from 'helpers/Validation';
import imageStore from 'utils/ImageDB';
import { values, clear } from 'idb-keyval';
import { notRequiredVal } from './Sections';
import Camera from 'components/Camera/Camera';
import { captureTRFImageHandler } from 'helpers/FormHelpers/helper';
import { setCurrentView } from 'journeys/portal/Home/Home.actions';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import {
    GetITFForm, submitNewFormOnlineOrOffline, submitExistingFormOnlineOrOffline,
    resetFormData, submitImagesOnlineOrOffline, deleteImagesFromDB,
    deleteImagesFromLocalDB, UpdateFormDataObj,
    updateImageNote, setIsEditimage, clearEditImageList,
    saveAftTestPointRangePrevVal, saveAltTestPointRangePrevVal,
    saveFlowAlarmTestPointRangePrevVal,
    saveSpinnerVisibleData
} from './Forms.actions';
import { setP81FormData, setSRAFormData, GetP8ITFForm, getAllP8MOImages, GetSRAForm } from './FormsP8.actions';
import { buildDataObject, areFilledAllMandatoryDataObject } from './FormHelpers/ParsedForm.data';
import { MRAT_USER_ROLE } from '../Home/Home.constants';
import { submitP8Forms } from './FormHelpers/submitP8Forms';
import { submitNewTRFImage, updateExistingTRFImage, submitNewP8TRFImage, updateExistingP8TRFImage, submitP8Image } from './TRFImaging.actions';
import { getAltDeleteIconStatus, hideAltDeleteIcons, showAltDeleteIcons } from './Sections/AsLeftTestTableSection/AsLeftTestTableSection.helpers';
import { getAftDeleteIconStatus, hideAftDeleteIcons, showAftDeleteIcons } from './Sections/AsFoundTestTableSection/AsFoundTestTableSection.helpers';
import { showFlowAlarmDeleteIcons } from './Sections/FlowAlarmTest/FlowAlarmTest.helpers';
import { Spinner, DarkBackground } from 'elements/Spinner/Spinner.styled';
import _ from 'lodash';
import { DraftAlert } from './FormHelpers/DraftAlert';
import { formDataCalculatedFieldsName } from './Forms.data';

const Form = (props) => {
    const { dispatch, deleteExistingImageList, loadITFForm, selectedTag, formData, formDataSRA, dpsData,
        saveP81FormData, saveSRAFormData, testMakeModelData, setCurrentAppView, selectedTrfId, currentView,
        formDataP81, userRole, clearEditimageList, resetFormDataValues, formName, totalCount, editedImageList,
        loadP8ITFForm, deleteNewImageList, setIsEditImages, saveNewFormOnlineOrOffline, saveExistingFormOnlineOrOffline, saveTRFImage,
        trfFormImage, updateTRFImage, updateimageNote, saveP8TRFImage, updateP8TRFImage,
        workType, saveUpdatedFormDataObj, updateAftTestPointRangePrevVal, draftStatus,
        flowAlarmTestPoint, updateFlowAlarmTestPointRangePrevVal, isMRATOnline, draftDuration,
        updateAltTestPointRangePrevVal, setSpinnerVisible, spinnerVisible, bpRegionNameAlias,
        aftTestPoint, altTestPoint, trfMasterList, multiTRFDetails, selectedAssetAliasName
    } = props;
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const [TrfLongDesc, setTrfLongDesc] = useState('');
    const [enableDraftButton, setEnableDraftButton] = useState(false);
    const [showDraftAlert, setShowDraftAlert] = useState(false);
    const [displayForm, setDisplayForm] = useState(true);
    const [capturedImages, setCapturedImages] = useState([]);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const nullChecker = (firstValue, secondValue) => firstValue ?? secondValue;
    const newTrfId = nullChecker(selectedTrfId, Trim(selectedTag.TrfId));
    const currentTrfId = Trim(selectedTag.TrfId);
    const widerFooter = '5rem';
    const uniqueFormName = `${selectedTag.Mi}_${selectedTag.HdWorkOrder}_${selectedTag.HdLocation}`;
    const buildDataObjectFunction = (_formData) => buildDataObject({
        selectedTag,
        userName,
        dpsData,
        distinctTestMakeModelData: testMakeModelData,
        formName,
        _formData,
        selectedTrfId,
        aftTestPoint,
        altTestPoint,
        flowAlarmTestPoint,
        multiTRFDetails
    });
    const initialFormData = buildDataObjectFunction(formData);
    const [formDataInitialState, setFormDataInitialState] = useState(initialFormData);
    const [formDataObj, setFormDataObj] = useState(initialFormData);
    const allMandatoryFieldsAreFilled = (_formData) => areFilledAllMandatoryDataObject({
        selectedTag,
        userName,
        dpsData,
        distinctTestMakeModelData: testMakeModelData,
        formName,
        _formData,
        selectedTrfId,
        aftTestPoint,
        altTestPoint,
        flowAlarmTestPoint,
        multiTRFDetails
    });

    const getImages = () => values(imageStore).then((val) => setCapturedImages([...val]));
    const isTRFP720P721 = ['P7-20', 'P7-21', 'M6-10', 'P6-26'].includes(newTrfId);
    const noOfRows = conditionalValueSelector(newTrfId === 'M6-10', 8, 10);
    const validTPRange = conditionalValueSelector(newTrfId === 'M6-10',
        formDataObj?.F376CleanDetectorLensesIfVisiblyDirty !== notRequiredVal,
        formDataObj.F102AftResult !== 'PASS');
    const trfDesc = trfMasterList.find((data) => data.TrfId === currentView);
    useEffect(() => {
        const trfTitleList = multiTRFDetails?.find((data) => data.TrfmasterId === currentView && data.Trftag === formDataObj.F956TrfTypeSelection);
        const newTrfLongDesc = nullChecker(trfTitleList?.Trftitle, trfDesc?.TrfLongDesc);
        setTrfLongDesc(newTrfLongDesc);
    }, [multiTRFDetails, formDataObj.F956TrfTypeSelection]);

    const headerText = (
        <div style={{ textAlign: 'center' }}>
            {`${currentView} ${TrfLongDesc}`}
            <br />
            MO
            {' '}
            {selectedTag.HdWorkOrder}
            <br />
            {conditionalValueSelector(workType.toLowerCase() !== 'corrective',
                `MI
                ${selectedTag.Mi}`,
                '')}
        </div>
    );
    const changeHandler = (field) => {
        if (isTRFP720P721 && !deleteWarning && !getAltDeleteIconStatus() && !getAftDeleteIconStatus()) {
            blurHandler(field);
        }
        setFormDataObj(currentFormDataObj => {
            const updatedFormData = buildDataObjectFunction({ ...currentFormDataObj, ...field });
            saveUpdatedFormDataObj(updatedFormData);
            return updatedFormData;
        });
    };
    const sectionHandlers = {
        Aft: {
            func: showAftDeleteIcons,
            testPointNumber: aftTestPoint,
            updateFunc: updateAftTestPointRangePrevVal,
            formDataKey: 'F675NumberOfTestPoints'
        },
        Alt: {
            func: showAltDeleteIcons,
            testPointNumber: altTestPoint,
            updateFunc: updateAltTestPointRangePrevVal,
            formDataKey: 'F721NumberOfTestPoints'
        },
        flowAlarm: {
            func: showFlowAlarmDeleteIcons,
            testPointNumber: flowAlarmTestPoint,
            updateFunc: updateFlowAlarmTestPointRangePrevVal,
            formDataKey: 'F816NumberOfTestPoints'
        }
    };
    const blurHandler = (field) => {
        const fieldName = Object.keys(field)[0];
        const fieldValue = Object.values(field)[0];
        const handler = Object.values(sectionHandlers).find(({ formDataKey }) => formDataKey === fieldName);
        if (handler && TestPointRange(fieldValue, noOfRows)) {
            handler.updateFunc(handler.testPointNumber);
            setDeleteWarning(displayPopUpMessage(fieldValue, handler.testPointNumber, noOfRows));
            if (!displayPopUpMessage(fieldValue, handler.testPointNumber, noOfRows)) {
                handler.updateFunc(fieldValue);
            }
        }
    };
    let tgcAftError = true;
    let tgcAltError = true;
    if (['M6-1', 'M6-11', 'M6-14'].includes(newTrfId)) {
        tgcAftError = checkTGCGreaterThanSetpointHH(formDataObj?.F240HighHighSetpoint, formDataObj?.F424AftAppliedTestGasConcentration);
        tgcAltError = checkTGCGreaterThanSetpointHH(formDataObj?.F240HighHighSetpoint, formDataObj?.F426AltAppliedTestGasConcentration);
    } else if (newTrfId === 'M6-15') {
        tgcAftError = checkTGCLessThanSetpointLL(formDataObj?.F241LowLowSetpoint, formDataObj?.F424AftAppliedTestGasConcentration);
        tgcAltError = checkTGCLessThanSetpointLL(formDataObj?.F241LowLowSetpoint, formDataObj?.F426AltAppliedTestGasConcentration);
    }
    const URVGreaterThanLRVError = checkURVGreaterThanLRV(formDataObj.F21HmiLrv, formDataObj.F22HmiUrv);
    const validNumberOfTP = conditionalValueSelector(validTPRange, TestPointRange(formDataObj.F675NumberOfTestPoints, noOfRows)
        && TestPointRange(formDataObj.F721NumberOfTestPoints, noOfRows),
        TestPointRange(formDataObj.F675NumberOfTestPoints, noOfRows));
    const RangeOfTestPoints = conditionalValueSelector(isTRFP720P721, validNumberOfTP, true);
    const InstrumentURVGreaterThanLRVError = checkURVGreaterThanLRV(formDataObj.F25InstrumentLrv, formDataObj.F26InstrumentUrv);
    const InstCalibratedURVGreaterThanLRVError = conditionalValueSelector(MatchOldTRFs(newTrfId), true,
        checkURVGreaterThanLRV(formDataObj.F116InstrumentCalibratedLrv, formDataObj.F117InstrumentCalibratedUrv));
    const HasICengapprovedvalue = icEngApprovedValueYes(formDataObj.F964HasIandCEngApproved);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const isFormExisting = formDataObj.RowId;
    const getCapturedImg = conditionalValueSelector(workType.toLowerCase() !== 'corrective',
        capturedImages.filter((img) => img.HdLocation === selectedTag.HdLocation
            && img.HdWorkOrder === selectedTag.HdWorkOrder
            && img.Mi === selectedTag.Mi),
        capturedImages.filter((img) => img.HdLocation === selectedTag.HdLocation
            && img.HdWorkOrder === selectedTag.HdWorkOrder));
    const updateFormData = (key, arraykeys, section) => {
        let updatedFormData = formDataObj;
        const handler = sectionHandlers[section];
        const { func, testPointNumber, updateFunc, formDataKey } = handler;
        for (let i = Number(key); i <= Number(testPointNumber); i += 1) {
            updatedFormData = arraykeys.reduce((acc, arrayKey) => ({
                ...acc,
                [`${arrayKey}${i}`]: acc[`${arrayKey}${i + 1}`]
            }), { ...updatedFormData });
        }
        setFormDataObj(buildDataObjectFunction(updatedFormData));
        const resetPrevValues = func(formDataObj?.[formDataKey], Number(testPointNumber) - 1);
        updateFunc(Number(testPointNumber) - 1);
        if (!resetPrevValues) {
            updateFunc(formDataObj?.[formDataKey]);
        }
    };
    useEffect(() => {
        setSpinnerVisible(true);
        const { HdLocation } = selectedTag;
        getImages();
        const dummyObj = currentView === 'P8-1' ? formDataP81 ?? undefined : formDataSRA;
        if (MatchSRA(Trim(newTrfId))) {
            loadP8ITFForm({ uniqueFormName, HdLocation, currentTrfId })
                .then((result) => {
                    setFormDataInitialState(() => _.isEmpty(dummyObj) ? buildDataObjectFunction(result) : dummyObj);
                    setFormDataObj(_.isEmpty(dummyObj) ? buildDataObjectFunction(result) : dummyObj);
                })
                .finally(() => setSpinnerVisible(false));
        } else {
            loadITFForm({ uniqueFormName, HdLocation, currentTrfId })
                .then((resultFormData) => {
                    hideAftDeleteIcons();
                    hideAltDeleteIcons();
                    setFormDataInitialState(() => buildDataObjectFunction(resultFormData));
                    setFormDataObj(() => buildDataObjectFunction(resultFormData));
                })
                .finally(() => setSpinnerVisible(false));
        }
        return () => {
            resetFormDataValues();
            setSpinnerVisible(false);
        };
    }, []);
    const [callbackTriggered, setCallbackTriggered] = useState(false);
    useEffect(() => {
        if (callbackTriggered) {
            buttonHandler();
            setCallbackTriggered(false); // Reset the callback trigger
        }
    }, [callbackTriggered]);
    const submitButtonHandler = () => {
        if (selectedTag?.InDraftState) {
            const draftObj = {
                Mo: selectedTag.HdWorkOrder,
                Mi: selectedTag.Mi,
                Floc: selectedTag.HdLocation,
                InDraftState: false
            };
            setFormDataObj(currentFormDataObj => {
                const tempObj = { ...currentFormDataObj, draftTrackingData: draftObj, InDraftState: false };
                setCallbackTriggered(true);
                return tempObj;
            });
        } else {
            setFormDataObj(currentFormDataObj => {
                const tempObj = { ...currentFormDataObj, InDraftState: false };
                setCallbackTriggered(true);
                return tempObj;
            });
        }
    };
    const draftButtonHandler = () => {
        setShowDraftAlert(true);
    };
    const draftHandler = (notice) => {
        setShowDraftAlert(false);
        const draftObj = {
            Region: bpRegionNameAlias.toUpperCase(),
            Asset: selectedAssetAliasName.toUpperCase(),
            TrfId: newTrfId,
            TrfformTitle: TrfLongDesc,
            Mo: selectedTag.HdWorkOrder,
            Mi: selectedTag.Mi,
            Floc: selectedTag.HdLocation,
            InDraftState: true,
            ReasonForDraft: notice,
            TimeInDraftAllowed: draftDuration
        };
        let draftDataObj = { ...draftObj };
        if (isFormExisting && selectedTag.InDraftState) {
            draftDataObj = {
                ...draftObj,
                LastModifiedBy: userName?.name,
                LastModifiedDate: formatDate(selectedTag.currentDateTime),
                LastModifiedDateUtc: formatDateToUTC(selectedTag.currentDateTime),
                LastModifiedDeviceType: isTablet ? 'Tablet' : 'Laptop',
                LastModifiedWifiState: isMRATOnline ? 'Online' : 'Offline',
                LastModifiedByEmail: userName?.username
            };
        } else {
            draftDataObj = {
                ...draftObj,
                CreatedBy: userName?.name,
                CreatedDate: formatDate(selectedTag.currentDateTime),
                CreatedDateUtc: formatDateToUTC(selectedTag.currentDateTime),
                CreatedWifiState: isMRATOnline ? 'Online' : 'Offline',
                CreatedDeviceType: isTablet ? 'Tablet' : 'Laptop',
                LastModifiedBy: userName?.name,
                LastModifiedDate: formatDate(selectedTag.currentDateTime),
                LastModifiedDateUtc: formatDateToUTC(selectedTag.currentDateTime),
                LastModifiedDeviceType: isTablet ? 'Tablet' : 'Laptop',
                LastModifiedWifiState: isMRATOnline ? 'Online' : 'Offline',
                LastModifiedByEmail: userName?.username
            };
        }
        setFormDataObj(currentFormDataObj => {
            const tempObj = { ...currentFormDataObj, draftTrackingData: draftDataObj, InDraftState: true };
            setCallbackTriggered(true);
            return tempObj;
        });
    };

    const handleImageCapture = async () => {
        const imgCanvas = await captureTRFImageHandler(userName?.name, formatDate(selectedTag.currentDateTime));
        return imgCanvas.toDataURL('image/png');
    };

    const handleP8FormSubmission = (isTRFImageCaptured, imageData) => {
        const newFormedObjectP81 = _.cloneDeep(formDataObj);
        conditionalValueSelector(
            isTRFImageCaptured,
            () => updateP8TRFImage(imageData, userName, isTRFImageCaptured?.Rowid, uniqueFormName),
            () => saveP8TRFImage(imageData, userName, uniqueFormName)
        )();
        const p8FormsObj = {
            formDataSRA,
            formDataObj,
            newFormedObjectP81,
            userName,
            selectedTag,
            saveExistingFormOnlineOrOffline,
            saveNewFormOnlineOrOffline,
            uniqueFormName,
            newTrfId
        };
        submitP8Forms(p8FormsObj);
    };

    const handleFormSubmission = (isTRFImageCaptured, imageData, imgDraftState) => {
        let tempFormObj = _.cloneDeep(formDataObj);
        if (tempFormObj?.draftTrackingData) {
            const trackingData = { ...tempFormObj.draftTrackingData, InDraftImage1: imageData?.split(',')[1] };
            tempFormObj = _.cloneDeep({ ...tempFormObj, draftTrackingData: trackingData });
        }
        conditionalValueSelector(
            isTRFImageCaptured,
            () => updateTRFImage(imageData, userName, isTRFImageCaptured?.Rowid, uniqueFormName, imgDraftState),
            () => saveTRFImage(imageData, userName, uniqueFormName, imgDraftState)
        )();
        const newFormName = MatchSRA(Trim(newTrfId)) ? `${uniqueFormName}_SRA` : uniqueFormName;
        if (isFormExisting) {
            saveExistingFormOnlineOrOffline(newFormName, tempFormObj, newTrfId, userName);
            editedImageList.length !== 0 && updateimageNote();
            clearEditimageList();
        } else {
            saveNewFormOnlineOrOffline(newFormName, tempFormObj, newTrfId, userName);
        }
    };

    const handleImageDeletion = async () => {
        if (!(MatchSRA(currentView)) && !formDataObj?.InDraftState) {
            deleteNewImageList.length !== 0 && await dispatch(deleteImagesFromLocalDB(deleteNewImageList));
            dispatch(submitImagesOnlineOrOffline());
            deleteExistingImageList.length !== 0 && dispatch(deleteImagesFromDB(uniqueFormName, deleteExistingImageList));
        }
    };

    const buttonHandler = async () => {
        getImages();
        setDisableSubmit(true);
        saveUpdatedFormDataObj(formDataObj);
        const imgDraftState = formDataObj.InDraftState;
        const imgData = await handleImageCapture();
        const isTRFImageCaptured = trfFormImage.find((data) => data.TrfId === selectedTag.TrfId.trim()
            && data.HdLocation === selectedTag.HdLocation && data.Mi === selectedTag.Mi && data.HdWorkOrder === selectedTag.HdWorkOrder);
        if (MatchSRA(currentView) && !formDataObj?.InDraftState) {
            saveSRAFormData(formDataObj);
            await submitP8Image(imgData);
            setCurrentAppView('P8-1');
        } else if (currentView === 'P8-1' && MatchSRA(Trim(newTrfId))) {
            handleP8FormSubmission(isTRFImageCaptured, imgData);
        } else {
            handleFormSubmission(isTRFImageCaptured, imgData, imgDraftState);
        }

        await handleImageDeletion();
    };
    const openCameraHandler = () => setDisplayForm(false);
    const closeCameraHandler = () => setDisplayForm(true);
    const switchSRAForm = () => {
        saveP81FormData(formDataObj);
        setCurrentAppView(newTrfId.trim());
    };

    const customizer = (objValue, othValue, key) => {
        if (formDataCalculatedFieldsName.includes(key)) {
            return true;
        }
        if ([null, undefined, ''].includes(objValue) && [null, undefined, ''].includes(othValue)) {
            return true;
        }
        // Return undefined to let Lodash handle other comparisons
        return undefined;
    };
    useEffect(() => {
        if (draftStatus) {
            if (!_.isEqualWith(formDataObj, formDataInitialState, customizer)) {
                setEnableDraftButton(true);
            } else {
                setEnableDraftButton(false);
            }
        }
    }, [formDataObj]);

    const handleNo = () => {
        setDeleteWarning(false);
        Object.values(sectionHandlers).forEach(({ formDataKey, testPointNumber }) => {
            if (displayPopUpMessage(formDataObj?.[formDataKey], testPointNumber, noOfRows)) {
                changeHandler({ [formDataKey]: testPointNumber });
            }
        });
    };
    const handleYes = () => {
        Object.values(sectionHandlers).forEach(({ func, testPointNumber, formDataKey }) => {
            if (displayPopUpMessage(formDataObj?.[formDataKey], testPointNumber, noOfRows)) {
                func(formDataObj?.[formDataKey], testPointNumber);
            }
        });
        setDeleteWarning(false);
    };
    const resetButtonHandler = () => {
        const { HdLocation } = selectedTag;
        if (MatchSRA(Trim(newTrfId)) && currentView !== 'P8-1') {
            clear(imageStore);
            dispatch(getAllP8MOImages());
            loadP8ITFForm({ uniqueFormName, HdLocation, currentTrfId })
                .then((resultFormData) => setFormDataObj(buildDataObjectFunction(resultFormData)));
        } else if (MatchSRA(Trim(newTrfId)) && currentView === 'P8-1') {
            dispatch(getAllP8MOImages());
            loadP8ITFForm({ uniqueFormName, HdLocation, currentTrfId })
                .then((resultFormData) => setFormDataObj(buildDataObjectFunction(resultFormData)));
        } else {
            loadITFForm({ uniqueFormName, HdLocation, currentTrfId })
                .then((resultFormData) => {
                    setFormDataObj(buildDataObjectFunction(resultFormData));
                    hideAftDeleteIcons();
                    hideAltDeleteIcons();
                });
        }
        setIsEditImages(false, {});
        clearEditimageList();
    };
    const areAllFieldsFilled = allMandatoryFieldsAreFilled(formDataObj);
    const userHasAccessToSubmit = (MRAT_USER_ROLE.Technician === userRole);
    const isFormReadOnly = (MRAT_USER_ROLE.View === userRole) || (MRAT_USER_ROLE.Engineer === userRole && selectedTag?.TrfRowId);
    return (
        <SiteWrapper
            headerText={headerText}
            formName={formName}
            titleFontSize="16pt"
            showOfflineFormsCount
            totalCount={totalCount}
            trfSelectionVisible={!selectedTag.DateTested && !(currentView === 'P8-1' && MatchSRA(newTrfId))}
            closeCameraButtonVisible={!displayForm}
            closeCameraHandler={closeCameraHandler}
            displayFormStatus={displayForm}
            openCameraButtonVisible={!(currentView === 'P8-1' && MatchSRA(newTrfId))}
            switchSRAForm={switchSRAForm}
            openCameraHandler={openCameraHandler}
            ButtonSection={(displayForm && !isFormReadOnly) ? <RefreshFormComponent resetButtonHandler={resetButtonHandler} /> : ''}
            footerButtonHandler={submitButtonHandler}
            draftButtonHandler={draftButtonHandler}
            draftButtonVisible={enableDraftButton && (isFormExisting ? selectedTag.InDraftState : true) && draftStatus && !areAllFieldsFilled && URVGreaterThanLRVError
                && InstrumentURVGreaterThanLRVError && InstCalibratedURVGreaterThanLRVError && RangeOfTestPoints && tgcAftError && tgcAltError && HasICengapprovedvalue
                && userHasAccessToSubmit && !getAltDeleteIconStatus() && !getAftDeleteIconStatus() && currentView !== 'P8-1'}
            FooterButtonVisible={areAllFieldsFilled && URVGreaterThanLRVError
                && InstrumentURVGreaterThanLRVError && InstCalibratedURVGreaterThanLRVError && RangeOfTestPoints && tgcAftError && tgcAltError && HasICengapprovedvalue
                && (MatchSRA(currentView) || userHasAccessToSubmit) && !getAltDeleteIconStatus() && !getAftDeleteIconStatus()}
            footerButtonText={conditionalValueSelector(MatchSRA(currentView), 'Mandatory P8-1', 'Submit')}
            widerFooter={widerFooter}
            height="calc(100vh - 168px)"
            goBack="tagselectionmoscreen"
            isDisabled={disableSubmit}
        >
            {deleteWarning && (
                <AlertDialog
                    dialogMessage="Do you want to continue with the Delete?"
                    dialogOpenState={deleteWarning}
                    handleClose={() => handleNo()}
                    handleAlertFunction={() => handleYes()}
                />
            )}
            {showDraftAlert && (
                <DraftAlert
                    dialogOpenState={showDraftAlert}
                    handleClose={() => setShowDraftAlert(false)}
                    handleAlertFunction={(notice) => draftHandler(notice)}
                />
            )}
            <DarkBackground disappear={!spinnerVisible}>
                <Spinner />
            </DarkBackground>
            {displayForm ? (
                <form id={formName} style={{ margin: '1rem' }}>
                    {React.Children.map(props.children, child => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, {
                                formData: formDataObj,
                                changeHandler,
                                formName,
                                dpsData,
                                isFormReadOnly,
                                updateFormData,
                                aftTestPoint,
                                altTestPoint,
                                flowAlarmTestPoint
                            });
                        }
                        return child;
                    })}
                </form>
            )
                : (
                    <Camera
                        selectedTag={selectedTag}
                        formData={formData}
                        capturedImages={getCapturedImg}
                        getImages={getImages}
                        readOnly={isFormReadOnly}
                    />
                )}
        </SiteWrapper>
    );
};

const mapStateToProps = ({ TestEquipment, Home, Forms, AppData }) => ({
    aftTestPoint: Forms.aftTestPoint,
    altTestPoint: Forms.altTestPoint,
    currentView: Home.currentView,
    selectedTag: Forms.selectedTag,
    formData: Forms.formData,
    formDataP81: Forms.formDataP81,
    formDataSRA: Forms.formDataSRA,
    deleteExistingImageList: Forms.deleteExistingImageList,
    deleteNewImageList: Forms.deleteNewImageList,
    dpsData: Forms.dpsData,
    testMakeModelData: TestEquipment.testEquipmentDataAll,
    editedImageList: Forms.editedImageList,
    selectedTrfId: Forms.selectedTrfId,
    userRole: Home.userRole,
    totalCount: Forms.totalCount,
    trfFormImage: Forms.trfFormImage,
    workType: Home.selectedWorkType,
    trfMasterList: Home.trfMasterList,
    multiTRFDetails: Forms.multiTRFDetails,
    getMultiTRFDetails: Forms.getMultiTRFDetails,
    flowAlarmTestPoint: Forms.flowAlarmTestPoint,
    spinnerVisible: Forms.spinnerVisible,
    draftStatus: Home.draftStatus,
    isMRATOnline: AppData.isMRATOnline,
    draftDuration: Home.draftDuration,
    bpRegionNameAlias: Home.bpRegionNameAlias,
    selectedAssetAliasName: Home.selectedAssetAliasName
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    loadP8ITFForm: ({ uniqueFormName, HdLocation, currentTrfId }) => dispatch(GetP8ITFForm({ uniqueFormName, HdLocation, currentTrfId })),
    loadSRAFormData: (selectedTag) => dispatch(GetSRAForm(selectedTag)),
    loadITFForm: ({ uniqueFormName, HdLocation, currentTrfId }) => dispatch(GetITFForm({ uniqueFormName, HdLocation, currentTrfId })),
    resetFormDataValues: () => dispatch(resetFormData()),
    setCurrentAppView: (view) => dispatch(setCurrentView(view)),
    saveP81FormData: (formData) => dispatch(setP81FormData(formData)),
    saveSRAFormData: (formData) => dispatch(setSRAFormData(formData)),
    saveExistingFormOnlineOrOffline: (formName, formData, trfId, userName) => dispatch(submitExistingFormOnlineOrOffline(formName, formData, trfId, userName)),
    saveNewFormOnlineOrOffline: (formName, formData, trfId, userName) => dispatch(submitNewFormOnlineOrOffline(formName, formData, trfId, userName)),
    saveTRFImage: (imgData, userName, uniqueFormName, imgDraftState) => dispatch(submitNewTRFImage(imgData, userName, uniqueFormName, imgDraftState)),
    updateTRFImage: (imgData, userName, rowid, uniqueFormName, imgDraftState) => dispatch(updateExistingTRFImage(imgData, userName, rowid, uniqueFormName, imgDraftState)),
    saveP8TRFImage: (imgData, userName, uniqueFormName) => dispatch(submitNewP8TRFImage(imgData, userName, uniqueFormName)),
    updateAftTestPointRangePrevVal: (testPoint) => dispatch(saveAftTestPointRangePrevVal(testPoint)),
    updateAltTestPointRangePrevVal: (testPoint) => dispatch(saveAltTestPointRangePrevVal(testPoint)),
    updateFlowAlarmTestPointRangePrevVal: (testPoint) => dispatch(saveFlowAlarmTestPointRangePrevVal(testPoint)),
    updateP8TRFImage: (imgData, userName, rowid, uniqueFormName) => dispatch(updateExistingP8TRFImage(imgData, userName, rowid, uniqueFormName)),
    updateimageNote: () => dispatch(updateImageNote()),
    setIsEditImages: (isEdit, img) => dispatch(setIsEditimage(isEdit, img)),
    saveUpdatedFormDataObj: (formData) => dispatch(UpdateFormDataObj(formData)),
    clearEditimageList: () => dispatch(clearEditImageList()),
    setSpinnerVisible: (spinnerValue) => dispatch(saveSpinnerVisibleData(spinnerValue)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form);

Form.propTypes = {
    aftTestPoint: PropTypes.number,
    altTestPoint: PropTypes.number,
    bpRegionNameAlias: PropTypes.string,
    children: PropTypes.array,
    clearEditimageList: PropTypes.func,
    currentView: PropTypes.string,
    deleteExistingImageList: PropTypes.array,
    deleteNewImageList: PropTypes.array,
    dispatch: PropTypes.any,
    dpsData: PropTypes.object,
    draftDuration: PropTypes.number,
    draftStatus: PropTypes.bool,
    editedImageList: PropTypes.array,
    flowAlarmTestPoint: PropTypes.number,
    formData: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    formDataP81: PropTypes.object,
    formDataSRA: PropTypes.object,
    formName: PropTypes.string,
    isMRATOnline: PropTypes.bool,
    loadITFForm: PropTypes.func,
    loadP8ITFForm: PropTypes.func,
    Mi: PropTypes.string,
    multiTRFDetails: PropTypes.array,
    resetFormDataValues: PropTypes.func,
    saveExistingFormOnlineOrOffline: PropTypes.func,
    saveNewFormOnlineOrOffline: PropTypes.func,
    saveP81FormData: PropTypes.func,
    saveP8TRFImage: PropTypes.func,
    saveSRAFormData: PropTypes.func,
    saveTRFImage: PropTypes.func,
    saveUpdatedFormDataObj: PropTypes.func,
    selectedAssetAliasName: PropTypes.string,
    selectedTag: PropTypes.object,
    selectedTrfId: PropTypes.string,
    setCurrentAppView: PropTypes.func,
    setIsEditImages: PropTypes.func,
    setSpinnerVisible: PropTypes.func,
    spinnerVisible: PropTypes.bool,
    testMakeModelData: PropTypes.array,
    totalCount: PropTypes.number,
    trfFormImage: PropTypes.array,
    trfMasterList: PropTypes.array,
    updateAftTestPointRangePrevVal: PropTypes.func,
    updateAltTestPointRangePrevVal: PropTypes.func,
    updateFlowAlarmTestPointRangePrevVal: PropTypes.func,
    updateimageNote: PropTypes.func,
    updateP8TRFImage: PropTypes.func,
    updateTRFImage: PropTypes.func,
    userRole: PropTypes.string,
    workType: PropTypes.string
};
