import { paddingValTPFR } from '../../ZeroFlowConditions/Data/P6022/P6022.data';

export const levelChangeCheckSection = (formData, formName) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'LevelChangeCheckLabel',
                label: 'Level Change Check',
                isLabel: true,
                xs: 12,
                fontWeight: '700',
                labelWidth: '100%',
                padding: '5px 0px 0px 4px',
                marginTop: '0',
            }]
        },
        {
            key: 1,
            fields: [{
                key: 'remark',
                label: `Remark: Increase or decrease process level by 5-10% if operational conditions permit.
                 Observe transmitter's response and compare with an independent level reading if possible.`,
                isLabel: true,
                xs: 12,
                fontWeight: '600',
                labelWidth: '99%',
                padding: '5px 0px 0px 4px',
                fontSize: '8.5pt',
                color: '#ea7c34',
                marginTop: '0'
            }]
        },
        {
            key: 2,
            fields: [
                {
                    key: 'nodata',
                    xs: 2,
                    isLabel: true
                },
                {
                    key: 'F813TransmitterTxreading1Label',
                    label: 'TX Reading (EU)',
                    isLabel: true,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    padding: paddingValTPFR,
                    xs: 2,
                    fontSize: '9pt',
                    marginTop: '1rem'
                },
                {
                    key: 'F302HmireadingLabel',
                    label: 'HMI Reading (HMI EU)',
                    isLabel: true,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    padding: paddingValTPFR,
                    xs: 2,
                    fontSize: '9pt',
                    marginTop: '1rem'
                },
                {
                    key: 'F469IndependentLevelReadingLabel',
                    label: 'Independent Level Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    padding: paddingValTPFR,
                    fontSize: '9pt'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'nodata',
                    xs: 2,
                    isLabel: true
                },
                {
                    key: 'F813TransmitterTxreading1',
                    value: formData.F813TransmitterTxreading1,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '0',
                },
                {
                    key: 'F302Hmireading',
                    value: formData.F302Hmireading,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '0'
                },
                {
                    key: 'F1026IndependentLevelReading',
                    value: formData.F1026IndependentLevelReading,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    marginBottom: '0'
                }
            ]
        },
        {
            key: 3,
            fields: [
                {
                    key: 'nodata',
                    xs: 2,
                    isLabel: true
                },
                {
                    key: 'F813TransmitterTxreading2',
                    value: formData.F813TransmitterTxreading2,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '0',
                },
                {
                    key: 'F302Hmireading1',
                    value: formData.F302Hmireading1,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0',
                    marginBottom: '0'
                },
                {
                    key: 'F1027IndependentLevelReading1',
                    value: formData.F1027IndependentLevelReading1,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    marginBottom: '0'
                }
            ]
        }
    ]
});
