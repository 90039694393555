import { labelWidth } from '../../../HMISection/Data/P710/P710.data';
import { Placeholder, Result, commonConstants } from 'journeys/portal/Forms/Sections.constants';
import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { AsLeftResultP630Formula } from './P630.formulas';

export const paddingVal = '4px 0 0 8px';
export const AsLeftTestTableSectionDataP630 = (formData) => {
    const disableAsLeftSection = (formData.F102AftResult === Result.RESULT_PASS || formData.F619AsLeftTestRequired === commonConstants.NO);
    const disableAsLeftCondition = (Value1, Value2) => disableAsLeftSection ? Value1 : Value2;
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsLeftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 5px 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'Attention',
                    label: 'Attention: The following simple corrections may be performed by technician as part of this PM work order — repeat stroking valve against stiction.',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '600',
                    labelWidth: '100%',
                    padding: '0px 1px 5px 9px',
                    fontSize: '8.5pt',
                    color: '#ea7c34'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'remark',
                    label: 'The following complicated corrections should be performed by technician as part of follow-up corrective work order — valve overhaul, actuator card replacement, lubricating and greasing valve or actuator, limit switch adjustments.',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '600',
                    labelWidth: '100%',
                    padding: '0px 1px 10px 9px',
                    fontSize: '8.5pt',
                    color: '#ea7c34'
                }]
            },
            {
                key: 4,
                fields: [
                    {
                        key: 'F1036AltexpectedValveActionOnDemandLabel',
                        label: 'Expected Valve Action On Demand',
                        xs: 4,
                        labelWidth: '100%',
                        padding: paddingVal,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F1036AltexpectedValveActionOnDemand',
                        value: disableAsLeftCondition('', formData.F1036AltexpectedValveActionOnDemand),
                        isDisabled: disableAsLeftSection,
                        isRequired: !disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        isDropdown: !disableAsLeftSection,
                        placeholder: Placeholder.Select_Action,
                        options: [{ name: Placeholder.Select_Action, value: '' },
                        { name: 'To be Opened', value: 'To be Opened' },
                        { name: 'To be Closed', value: 'To be Closed' }],
                        labelWidth: '0%',
                        width: '100%',
                        inputTextAlign: 'center',
                        borderBottom: '0',
                        xs: 2,
                        height: disableAsLeftCondition('1.25rem', '1.5rem')
                    },
                    {
                        key: 'F1039AlthmivalvePositionAfterDemandLabel',
                        label: 'HMI Valve Position After Demand',
                        xs: 4,
                        labelWidth: '97%',
                        padding: paddingLabel,
                        isLabel: true,
                        textAlign: 'right',
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F1039AlthmivalvePositionAfterDemand',
                        value: disableAsLeftCondition('', formData.F1039AlthmivalvePositionAfterDemand),
                        isDisabled: disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        isDropdown: !disableAsLeftSection,
                        placeholder: Placeholder.SELECT_HMI_POSITION,
                        options: [{ name: Placeholder.SELECT_HMI_POSITION, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                        labelWidth: '0',
                        width: '98%',
                        inputTextAlign: 'center',
                        inputMarginRight: disableAsLeftCondition('4px', ''),
                        xs: 2,
                        borderBottom: '0',
                        height: disableAsLeftCondition('1.25rem', '1.5rem'),
                        borderRight: '0'
                    }
                ]
            },
            {
                key: 5,
                fields: [
                    {
                        key: 'F1037AltphysicalValvePositionAfterDemandLabel',
                        label: 'Physical Valve Position After Demand',
                        xs: 4,
                        labelWidth: '100%',
                        padding: paddingVal,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F1037AltphysicalValvePositionAfterDemand',
                        value: disableAsLeftCondition('', formData.F1037AltphysicalValvePositionAfterDemand),
                        isDisabled: disableAsLeftSection,
                        isRequired: !disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        isDropdown: !disableAsLeftSection,
                        placeholder: Placeholder.SELECT_PHYSICAL_POSITION,
                        options: [{ name: Placeholder.SELECT_PHYSICAL_POSITION, value: '' },
                        { name: 'Open', value: 'Open' },
                        { name: 'Closed', value: 'Closed' },
                        { name: 'Intermediate', value: 'Intermediate' }],
                        labelWidth: '0%',
                        width: '100%',
                        inputTextAlign: 'center',
                        xs: 2,
                        borderBottom: '0',
                        height: disableAsLeftCondition('1.25rem', '1.5rem')
                    },
                    {
                        key: 'F1040AltvalveTravelTimeLabel',
                        label: 'Valve Travel Time (sec)',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        labelWidth,
                        padding: paddingLabel,
                        fontSize: '9pt',
                        textAlign: 'right',
                    },
                    {
                        key: 'F1040AltvalveTravelTime',
                        value: disableAsLeftCondition('', formData.F1040AltvalveTravelTime),
                        isDisabled: disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        width: '100%',
                        labelWidth: '0%',
                        xs: 2,
                        textAlign: 'center',
                        borderBottom: '0',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        fontWeight: '700',
                        height: '1.25rem',
                        type: 'number',
                        maxLength: '10'
                    }
                ]
            },
            {
                key: 6,
                fields: [
                    {
                        key: 'F1038AltobservedValveMovementLabel',
                        label: 'Observed Valve Movement',
                        xs: 4,
                        labelWidth: '100%',
                        padding: paddingVal,
                        isLabel: true,
                        fontWeight: '600',
                        fontSize: '9pt',
                    },
                    {
                        key: 'F1038AltobservedValveMovement',
                        value: disableAsLeftCondition('', formData.F1038AltobservedValveMovement),
                        isDisabled: disableAsLeftSection,
                        isRequired: !disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        isDropdown: !disableAsLeftSection,
                        placeholder: Placeholder.Select_Valve_Movement,
                        options: [{ name: Placeholder.Select_Valve_Movement, value: '' },
                        { name: 'Smooth', value: 'Smooth' },
                        { name: 'Abnormal', value: 'Abnormal' }],
                        labelWidth: '0%',
                        width: '100%',
                        inputTextAlign: 'center',
                        xs: 2,
                        height: disableAsLeftCondition('1.25rem', '1.5rem')
                    },
                    {
                        key: 'F1041AltmaxTorqueDuringValveTravelLabel',
                        label: 'Max Torque During Valve Travel',
                        xs: 4,
                        isLabel: true,
                        fontWeight: '600',
                        labelWidth,
                        padding: paddingLabel,
                        fontSize: '9pt',
                        textAlign: 'right',
                    },
                    {
                        key: 'F1041AltmaxTorqueDuringValveTravel',
                        value: disableAsLeftCondition('', formData.F1041AltmaxTorqueDuringValveTravel),
                        isDisabled: disableAsLeftSection,
                        readOnly: disableAsLeftSection,
                        width: '100%',
                        labelWidth: '0%',
                        xs: 2,
                        textAlign: 'center',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        fontWeight: '700',
                        borderBottom: '0',
                        height: disableAsLeftCondition('1.36rem', '1.25rem'),
                        maxLength: '20'
                    }
                ]
            },
            {
                key: 7,
                fields: [
                    {
                        key: 'Nodata',
                        isLabel: true,
                        xs: formData.F1038AltobservedValveMovement === 'Abnormal' ? 4 : 6,
                    },
                    ...(formData.F1038AltobservedValveMovement === 'Abnormal'
                        ? [
                            {
                                key: 'displaymessage',
                                label: 'Corrective work may be required to fix the abnormal valve movement.',
                                isLabel: true,
                                fontWeight: '600',
                                labelWidth: '100%',
                                padding: '0 0 0 2px',
                                fontSize: '8.5pt',
                                color: '#ea7c34',
                                xs: 4,
                            }] : []),
                    {
                        key: 'F81AsLeftResultLabel',
                        label: 'As-Left Result',
                        xs: formData.F1038AltobservedValveMovement === 'Abnormal' ? 2 : 4,
                        isLabel: true,
                        fontWeight: '700',
                        labelWidth,
                        padding: paddingLabel,
                        fontSize: '9pt',
                        textAlign: 'right',
                    },
                    {
                        key: 'F81AsLeftResult',
                        value: disableAsLeftCondition('', AsLeftResultP630Formula(formData)),
                        isDisabled: disableAsLeftSection,
                        width: '100%',
                        readOnly: true,
                        labelWidth: '0%',
                        isCalculatedNew: true,
                        xs: 2,
                        textAlign: 'center',
                        borderRight: '0',
                        inputMarginRight: '4px',
                        fontWeight: '700',
                        isLabelBold: true,
                        borderBottom: '0',
                        height: '1.25rem',
                        marginBottom: '4px'
                    }
                ]
            },
        ]

    };
};
