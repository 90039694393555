import styled from 'styled-components';

const textCentreAlign = ['P6-30'];
export const StyleTestDropdown = styled.div`
#F1042LocalOpenCloseSuccessful-wrapper,
#F343PhysicalValvePositionAfterTest-wrapper,
#F1043LocalRemoteSelectionAfterTest-wrapper,
#F1044HandWheelOperationSuccessful-wrapper,
#F347HmivalvePositionAfterTest-wrapper
 {
    .Dropdown-menu,
    .Dropdown-placeholder {
      text-align: ${props => textCentreAlign.includes(props.formName) ? 'center' : 'left'};
    }
    .Dropdown-placeholder {
      width: ${props => textCentreAlign.includes(props.formName) ? '100%' : '93%'};
    }
  }

#F382HmistatusAfterTest-wrapper .Dropdown-menu {
    overflow-y: hidden;
    text-align: ${props => (['M6-7', 'M6-9', 'M6-12', 'M6-3', 'M6-4', 'M6-5', 'M6-6',
        'M6-11', 'M6-1', 'M3-1', 'P7-23', 'M6-10', 'M6-14', 'M6-15'].includes(props.formName)) ? 'center' : 'left'};
}
#F404JumperWireRemovedandShortCircuitEliminated-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'M6-9' || props.formName === 'M6-5') ? 'center' : 'left'};
}
#F429DetectorCleanedFromDust-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'M6-6') ? 'center' : 'left'};
}
#F446PhysicalPositionofContactirAt-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'P6-11' || props.formName === 'P6-20'
        || props.formName === 'P7-17' || props.formName === 'P7-33') ? 'center' : 'left'};
}
#F728DischargeValvePositionAt-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'M7-1') ? 'center' : 'left'}
};
#F660StatusNotificationApplianceAt-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'M6-16') ? 'center' : 'left'}
};
#F577SolenoidValvePositionAfterTest-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'P6-15') ? 'center' : 'left'};
}
#F597RelayCoilStatusAfterTest-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'P7-32') ? 'center' : 'left'};
}
#F1017CrticalFault-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'M4-2') ? 'center' : 'left'};
}
.Dropdown-menu {
    overflow-y: hidden;
}
.Dropdown-option {
    min-height: 22pt;
    padding: 5px;
}
#F409LocalIndication-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'M6-12') ? 'center' : 'left'};
}

.Dropdown-placeholder {
    width: ${props => (['M6-3', 'M6-4', 'M6-5', 'M6-6', 'M6-7', 'M6-9', 'M6-10', 'M6-11',
        'M6-12', 'M6-14', 'P6-11', 'M6-15', 'P7-23', 'P7-17', 'P7-32', 'P7-33', 'P6-20', 'M6-16',
        'M7-1', 'P8-14.1', 'M6-1', 'M4-2', 'P6-29'].includes(props.formName))
        ? '100%' : '93%'};
}
#F143HmiswitchStatusAfterTest-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'P8-14.1' || props.formName === 'P6-29') ? 'center' : 'left'};
    width: ${props => (props.formName === 'P8-14.1' || props.formName === 'P6-29') ? '100%' : '93%'};
}
#F82HmiReadingAt-wrapper .Dropdown-menu {
    text-align: ${props => (props.formName === 'P6-25') ? 'center' : 'left'};
}
`;
